import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../shared/app-material/app-material.module';

@Component({
    selector: 'app-header',
    imports: [AppMaterialModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {

}
