import { Component, Input } from '@angular/core';
import { AppMaterialModule } from '../../app-material/app-material.module';
import { ICard } from '../../interfaces/ICard';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-simple-card',
  imports: [AppMaterialModule, NgOptimizedImage],
  templateUrl: './simple-card.component.html',
  styleUrl: './simple-card.component.scss'
})
export class SimpleCardComponent {
  @Input() data!: ICard;
}
