import { Component } from '@angular/core';
import { MainSectionComponent } from '../../components/main-section/main-section.component';
import { CompleteSolutionSectionComponent } from '../../components/complete-solution-section/complete-solution-section.component';
import { ReceivablesSectionComponent } from '../../components/receivables-section/receivables-section.component';
import { WelcomeSectionComponent } from '../../components/welcome-section/welcome-section.component';
import { FaqSectionComponent } from '../../components/faq-section/faq-section.component';
import { WhyUsComponent } from '../../components/why-us/why-us.component';
import { StepByStepSectionComponent } from '../../components/step-by-step-section/step-by-step-section.component';

@Component({
  selector: 'app-website-home',
  imports: [
    MainSectionComponent,
    CompleteSolutionSectionComponent,
    ReceivablesSectionComponent,
    WelcomeSectionComponent,
    FaqSectionComponent,
    WhyUsComponent,
    StepByStepSectionComponent
  ],
  templateUrl: './website-home.component.html',
  styleUrl: './website-home.component.scss'
})
export class WebsiteHomeComponent {

}
