<main class="container">
  <h1 class="my-4 text-center text-lg-start">Perguntas Frequentes</h1>
  <section class="my-4">
    <app-asks-expansion-panel></app-asks-expansion-panel>
  </section>

  <section class="text-center my-4">
    <button
      onclick="window.open('https://api.whatsapp.com/send/?phone=5547992157013&text&app_absent=1')"
      mat-stroked-button class="my-2 p-4"
      class="bg-white p-4 w-50"
    >
      <img class="me-2" src="assets/svg/whatsapp.svg" alt="Ícone do WhatsApp.">
      <span class="fs-6">Whatsapp</span>
    </button>
  </section>

</main>
