<main class="container p-4">
  <h1 class="text-center">POLÍTICA DE PRIVACIDADE / LGPD</h1>
  <p>
    A Broadfactor desenvolveu a presente Política de Privacidade com base na Lei Geral de Proteção de Dados (LGPD),
    a fim de tratar, da forma mais transparente possível os seus usuários, colaboradores internos e externos,
    prestadores de serviços, parceiros, e principalmente o seu cliente, as informações cadastrais, pessoais e/ou financeiras,
    e as regras gerais de coleta, uso, armazenamento, tratamento, proteção, compartilhamento e exclusão de dados.
  </p>
  <p>
    Todos os canais internos da Broadfactor estão submetidos a esta Política, e podem ser ferramentas de obtenção de informações decorrentes da análise deste material.A Broadfactor tratará apenas os Dados Pessoais para os quais tenha base legal, conforme definido na LGPD.
  </p>
  <p>
    Caso o cliente final não concorde com a presente Política, o mesmo não deve consentir com a aceitação de crédito, objetivo final de nossa instituição, e muito menos utilizar os serviços disponíveis diretamente através da Broadfactor e/ou através de seus parceiros, colaboradores ou qualquer outro intermediário.
  </p>
  <p>
    Não é possível oferecer nossos serviços sem ter acesso a dados pessoais. Ou seja, o tratamento dos dados pessoais é condição de acesso aos nossos serviços.</p>
  <p>
    O cliente final fica ciente que eventual solicitação de exclusão de dados, de natureza imprescindível à continuidade da operação, poderá implicar no cancelamento dos serviços prestados, ou na impossibilidade de atendimento da solicitação.
  </p>

  <h2>1. O que é LGPD?</h2>

  <p><b>A Lei brasileira nº 13.709, de 14 de agosto de 2018</b> (<b>Lei Geral de Proteção aos Dados – LGPD</b>), é um conjunto de regras, normas e direitos sobre o uso de dados pessoais, que protege e viabiliza os direitos individuais de privacidade e proteção aos dados pessoais.</p>

  <h2>2. Quais serão os dados coletados?</h2>

  <p>A Broadfactor trata dados pessoais daqueles que tem ou tiveram relacionamento conosco, seja na condição de cliente, representante, procurador, empregado, parceiro de negócios, prestadores de serviço, colaboradores, empresa ou entidade com a qual nos relacionamos, e que realizaram alguma transação conosco ou com nossos clientes, parceiros, fornecedores e prestadores de serviço.</p>

  <p>A Broadfactor poderá coletar e utilizar diversos dados dos seus clientes durante a contratação de seus serviços e produtos, os quais podem ser classificados em diversas categorias, conforme a finalidade de uso:</p>

  <ul>
    <li>
      <b>Dados Cadastrais:</b> dados que identificam pessoalmente cada indivíduo, seja através de nome, data de nascimento, RG, CPF e/ou outros documentos de identificação, como carteira de habilitação e carteiras de classe profissional, foto, endereço residencial e comercial, telefones residencial, comercial e celular, e-mail, profissão, ocupação, estado civil, nacionalidade, naturalidade, PEP – pessoa politicamente exposta, entre outros.
    </li>
    <li>
      <b>Dados Pessoais Sensíveis:</b> categoria especial de dados pessoais, referentes a origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
      Ressaltamos que, na análise necessária para obtenção de crédito, não são utilizados dados sensíveis.
      A utilização de dados sensíveis pode ser feita, por exemplo, na coleta de dados biométricos, necessários para   certificar a identidade do titular e/ou por questões de prevenção a fraudes e segurança.

    </li>
    <li>
      <b>Dados Financeiros:</b> dados de operações e movimentações financeiras de nossos clientes provenientes de operações financeiras e órgãos de análise de crédito, estabelecidas pelo mercado financeiro como SCR, SERASA, SPC, entre outros.
    </li>
    <li>
      <b>Dados de navegação:</b> dados referentes a sua navegação em nosso site.
    </li>
    <li>
      <b>Informações sobre Dispositivos:</b> informações sobre o dispositivo utilizado para acessar nossa Plataforma.
    </li>
  </ul>

  <p>
    Esclarecemos que a Broadfactor <b>não trabalha soluções</b>, produtos e serviços destinados a crianças e adolescentes.
  </p>


  <h2>3. Quais são as fontes de dados coletados?</h2>

  <p>
    A Broadfactor recebe ou coleta dados pessoais através de navegações na plataforma e fornecimento de informações por cadastros ou formulários, análise de crédito, contratação de serviços, contatos em nossos canais de atendimento, entre outros formas. Também recebemos dados pessoais para operacionalização de transações nas quais o Titular seja o beneficiário.
  </p>
  <p>
    A Broadfactor, além de obter e capturar informações dos seus canais, soluções, produtos e serviços disponibilizados em sites, aplicativos, ferramentas de integração e redes sociais, também poderá obter informações de clientes finais, a partir de informações obtidas diretamente com parceiros, correspondentes bancários, fontes públicas ou ferramentas de mercado disponíveis para obtenção de informações.
    Para conhecimento, a Broadfactor poderá utilizar acesso a páginas de sites ou APPs, cookies, tags, e objetos diversos de Internet como scripts, integrações por APIs, webviwes, iframes, controle de IP, etc. Caso haja interesse de bloquear o uso destas ferramentas, o objetivo final da contratação de um serviço ou produto poderá não ser atendido.
  </p>
  <p>
    Com a aceitação e interesse de contratação dos nossos produtos e serviços, seja automaticamente, e também através de cláusula destacada, o nosso cliente concorda de forma livre, espontânea e voluntária com o compartilhamento dos seus dados direta ou indiretamente, concordando ainda estar livre de dúvidas sobre esta política e sobre sua execução, e da Lei Geral de Proteção de Dados.
  </p>
  <p>
    Você será o único e exclusivo responsável pela qualidade e veracidade dos dados Pessoais fornecidos à Broadfactor na realização do seu cadastro, cotação e/ou contratação de eventuais produtos ou serviços. A Broadfactor não possui qualquer responsabilidade pela veracidade dos dados fornecidos, bem como por eventuais danos decorrentes da inexatidão e/ou desatualização de referidas informações.
  </p>
  <p>
    Podemos utilizar certas tecnologias de monitoramento das atividades realizadas no site. Esta tecnologia coleta informações que serão utilizadas em medições de performance, identificação de problemas no uso, captar o comportamento dos usuários de forma geral e coletar dados de impressão de conteúdo. Os cookies são esta tecnologia de monitoramento.
  </p>
  <p>
    Ressaltamos que utilizamos cookies necessários para o funcionamento correto do site e serviços oferecidos, e que qualquer alteração do usuário no navegador de sua preferência poderá afetar o funcionamento, bem como aspectos de segurança da informação.
  </p>
  <h2>4. Como poderemos utilizar a sua informação.</h2>
  <p>A Broadfactor e seus parceiros, correspondentes e colaboradores poderá utilizar os dados coletados para uma série de propósitos, incluindo, mas não se limitando:</p>

  <ul>
    <li>Iniciar, administrar ou rescindir contratos com os Usuários</li>
    <li>Informar sobre produtos e serviços que possam ser de interesse dos Usuários</li>
    <li>Facilitar a identificação do perfil e necessidades dos Usuários</li>
    <li>Elaborar estudos e estatísticas</li>
    <li>Cumprir obrigações legais e regulatórias</li>
    <li>Tomar decisões automatizadas (incluindo a criação de perfis) com a finalidade de realizar cálculo de contratações, pontuação e score</li>
    <li>Compartilhar dados com parceiros, prestadores de serviços, bureaus de crédito cadastrais e/ou restritivos, SCR Bacen, ou outros que lhe permitam como controlador e operador, efetuar a análise necessária a conclusão e entrega de seus produtos e serviços</li>
    <li>Processar e cumprir pedidos, e fornecer as informações e os serviços da Broadfactor que foram solicitados</li>
    <li>Fornecer ao titular alertas, mensagens e boletins informativos que registrou para receber</li>
    <li>Fornecer mensagens de serviços, incluindo mensagens para notificá-lo sobre alterações nos serviços do Broadfactor ou alterações nos nossos termos, condições e políticas.</li>
    <li>Cessões de crédito e transferência de direitos e deveres</li>
    <li>Instrução e defesa de processos judiciais, administrativos ou arbitrais.</li>
    <li>Realização de auditorias internas e externas.</li>
    <li>Cumprimento de obrigações contratuais</li>
    <li>Realização de cobranças, extrajudicial ou judicial</li>
    <li>Comunicação e contato com clientes e titulares</li>
    <li>Esclarecimento de dúvidas e reclamações e gestão de canais de atendimento</li>
    <li>Garantir maior segurança e prevenir fraudes</li>
    <li>Assegurar sua adequada identificação, qualificação e autenticação</li>
    <li>Prevenir atos relacionados à lavagem de dinheiro e outros atos ilícitos</li>
    <li>Conhecer perfil de risco de crédito e realizar análise de crédito</li>
    <li>Outras hipóteses baseadas em finalidades legítimas, como apoio e promoção de atividades da Broadfactor, ou para a prestação de serviços que beneficiem os clientes</li>
  </ul>

  <h2>5. Tempo de Armazenamento</h2>
  <p>
    Os dados são armazenados e serão eliminados quando atingirem os fins a que se destinam, ou quando solicitado pelo Titular, desde que a eliminação não interfira no cumprimento de obrigação legal ou regulatória, e inclusive para o exercício de direitos em processos judiciais ou administrativos.
  </p>
  <h2>6. Como protegemos a sua informação.</h2>

  <ul>
    <li>Uso de controle restrito sobre o acesso aos dados mediante a definição de responsabilidades das pessoas que terão possibilidade de acesso, e de privilégios mínimos de acesso exclusivo para determinados responsáveis.</li>
    <li>Uso de mecanismos de autenticação de acesso aos registros.</li>
    <li>Uso de política de senha que possua uma padronização de senha forte e bloqueio por tentativas sem sucesso.</li>
    <li>Os ambientes onde serão tratados dados são monitorados por meio de soluções nacionais e internacionais de proteção de acesso aos dados, e controle de invasores e outros.</li>
    <li>Dados são armazenados em banco de dados protegidos por empresas terceiras, especializadas em segurança e controle da informação.</li>
    <li>Respeitamos as principais práticas de mercado, ambientes seguros, sem acesso humano, com controles de senhas e ferramentas que protegem o perímetro e toda forma de acesso.</li>
    <li>Armazenamos os seus dados em acordo com as obrigações legais das entidades do governo que regem a nossa operação como Banco Central do Brasil, Sistema Brasileiro de Pagamentos (SPB), entre outros.</li>
    <li>Os dados poderão ser processados dentro e fora do Brasil por uma questão de replicação de dados e segurança da informação, mas, o controle, gestão e manutenção dos dados é exclusivo da Broadfactor Plus, respeitando-se todas as regras e formalidades aqui discutidas.</li>
    <li>Todos os controles e armazenamentos, respeitam as normais legais e regulatórias vigentes no ambiente brasileiro.</li>
    <li>Como todo armazenamento de dados no mundo WEB, respeitamos todas as regras e normas possíveis, e, utilizamos fornecedores profundamente reconhecidos no âmbito nacional e internacional, todavia, não podemos garantir, assim como todos os fornecedores de soluções em Internet do mundo, a inviolabilidade de acesso a dados. E na remota hipótese da ocorrência de episódios desta natureza, adotaremos todos os esforços para apresentação de solução.</li>
  </ul>

  <h2>7. Direitos e deveres dos clientes</h2>


  <p>A Broadfactor disponibilizará aos seus clientes, uma vez que solicitado, acesso aos seus dados cadastrais e financeiros, ajustes nos mesmos e exclusão dos mesmos, desde que sua solicitação não afete as regras e sanções legais de armazenamento impostas à Broadfactor como entidade financeira.</p>

  <p>O exercício deste direito deve ser feito através de requisição em nossos canais de atendimento.</p>

  <p>O acesso aos seus dados, seja por entrega de informações ou manutenção/uso acessos por identificação e senhas, é de total responsabilidade de nosso cliente, logo, cabe ao mesmo zelar pelo seu armazenamento e não compartilhamento.</p>

  <h2>8. Legislação</h2>

  <p>Esta Política será regida, interpretada e executada de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro do local de residência do cliente, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. O cliente consente, expressamente, com a competência desse juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</p>

  <p>Nos reservamos o direito de modificar, alterar, acrescentar ou remover partes deste documento a qualquer momento.</p>
  <h2>9. Fale Conosco</h2>

  <p>Em caso de dúvidas sobre a Lei LGPD e Política de Privacidade, não hesite em nos questionar diretamente pelo telefone, Redes Sociais, ou e-mail.</p>

  <b class="d-block"> Telefone: (47) 99215-7013.</b>
  <b class="d-block my-2">O respeito a sua informação cadastral, pessoal e/ou financeira é nossa responsabilidade e prioridade, e, para nós, a transparência nos tratamentos da mesma, é o maior objetivo.</b>
</main>
