import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { SimpleCardComponent } from '../../../../shared/components/simple-card/simple-card.component';

import { SolutionsUtil } from '../../utils/solutions-util';
import { ICard } from '../../../../shared/interfaces/ICard';



@Component({
  selector: 'app-solutions',
  imports: [AppMaterialModule, SimpleCardComponent, NgFor],
  templateUrl: './solutions.component.html',
  styleUrl: './solutions.component.scss',
})
export class SolutionsComponent {
  solutionList : ICard[] = SolutionsUtil.getSolutions();
}
