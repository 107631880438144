import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';

@Component({
  selector: 'app-step-by-step-section',
  imports: [AppMaterialModule],
  templateUrl: './step-by-step-section.component.html',
  styleUrl: './step-by-step-section.component.scss'
})
export class StepByStepSectionComponent {

}
