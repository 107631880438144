import { Component } from '@angular/core';
import { AppMaterialModule } from '../../shared/app-material/app-material.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'app-help-center',
    imports: [AppMaterialModule, NgOptimizedImage],
    templateUrl: './help-center.component.html',
    styleUrl: './help-center.component.scss'
})
export class HelpCenterComponent {

}
