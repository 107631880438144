import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { AsksExpansionPanelComponent } from '../../../faq/components/asks-expansion-panel/asks-expansion-panel.component';

@Component({
  selector: 'app-faq-section',
  imports: [AppMaterialModule, AsksExpansionPanelComponent],
  templateUrl: './faq-section.component.html',
  styleUrl: './faq-section.component.scss'
})
export class FaqSectionComponent {

}
