import { IDocumentList } from './../../interfaces/IDocumentList';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-upload-card',
    imports: [AppMaterialModule, NgFor, NgIf, NgClass],
    templateUrl: './upload-card.component.html',
    styleUrl: './upload-card.component.scss'
})
export class UploadCardComponent {
  @Input() item!:IDocumentList;
  @Input() showError = false;
  @Output() add = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();


  addDocument(event: any) {
    this.add.emit(event);
  }

  removeDocument(index: number) {
    this.remove.emit(index);
  }
}
