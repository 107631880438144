import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-complete-solution-section',
  imports: [AppMaterialModule, NgOptimizedImage],
  templateUrl: './complete-solution-section.component.html',
  styleUrl: './complete-solution-section.component.scss'
})
export class CompleteSolutionSectionComponent {

}
