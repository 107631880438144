import { ICard } from "../../../shared/interfaces/ICard";

export class SolutionsUtil {
  static getSolutions () : ICard[]{
    return [
      {
        icon: 'money.svg',
        title: 'Antecipação de recebíveis ',
        description: 'Antecipamos seus recebíveis, seja das vendas para empresas ou para o governo. ',
      },
      {
        icon: 'computer.svg',
        title: 'Conta Digital',
        description: 'Gerencie suas finanças de forma simples e segura com a nossa conta digital.',
      },
      {
        icon: 'code.svg',
        title: 'Cobrança bancária',
        description: 'Facilite a gestão de pagamentos com nossa cobrança bancária segura.',
      },
      {
        icon: 'checked.svg',
        title: 'Recarga de celular ',
        description: 'Carregue créditos de forma rápida e prática diretamente pela sua conta digital.',
      },
      {
        icon: 'bnds.svg',
        title: 'BNDES',
        description: 'Crédito especializado para quem precisa de investimentos de longo prazo.',
      },
      {
        icon: 'wallet.svg',
        title: 'Saldos e Extratos',
        description: 'Acompanhe suas movimentações em tempo real com total transparência e facilidade.',
      },
      {
        icon: 'wallet-checked.svg',
        title: 'Transferências',
        description: 'Realize transferências para qualquer banco de forma rápida, segura e sem complicações.',
      },
      {
        icon: 'money-safe.svg',
        title: 'Financiamentos ',
        description: 'Impulsione seus projetos com financiamentos rápidos e acessíveis.',
      },
      {
        icon: 'transfer.svg',
        title: 'Cartão de  crédito',
        description: 'Tenha acesso a limites, beneficíos exclusivos e flexibilidade para realizar seus planos.',
      },
      {
        icon: 'pix.svg',
        title: 'PIX',
        description: 'Com o Pix, seus pagamentos caem na hora, de forma simples e rápida.',
      },
      {
        icon: 'wallet-plus.svg',
        title: 'Hot Money - Crédito Rotativo ',
        description: 'Obtenha recursos imediatos para necessidades urgentes com um crédito flexível e de curto prazo.',
      },
      {
        icon: 'banking.svg',
        title: 'Open Banking',
        description: 'Emita boletos, programe débitos automáticos e integre suas finanças via Open Banking.',
      },
      {
        icon: 'coin.svg',
        title: 'Pagamentos Facilitados ',
        description: 'Efetue pagamentos e transferências de forma segura.',
      },
      {
        icon: 'money-paper.svg',
        title: 'Capital de Giro',
        description: 'Garanta o funcionamento do seu negócio com crédito acessível para manter o fluxo de caixa ativo.',
      },
    ]
  }
}
