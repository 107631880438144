import { Component } from '@angular/core';

@Component({
    selector: 'app-success-page',
    imports: [],
    templateUrl: './success-page.component.html',
    styleUrl: './success-page.component.scss'
})
export class SuccessPageComponent {

}
