@if (!finished) {
  <main class="min-vh-100">
    <section class="bg-primary container-fluid mh-50 section">
      <div class="row d-flex align-items-center">
        <figure class="col-md-4 text-center ">
          <img src="assets/svg/open-your-account.png" alt="Imagem de uma moça animada">
        </figure>
        <div class="col-md-8 text-center d-flex flex-column justify-content-center">
          <h1>Abra sua conta!</h1>
          <p>Preencha o formulário e nossa equipe entrará em contato em breve.</p>
          <p><b>Importante</b>: você precisa de um CNPJ ativo para abrir a sua conta.</p>
          <div class="d-flex justify-content-center align-items-end pt-1">
            <div>
              <img class="mb-3" height="50"  src="assets/svg/conexão.svg" alt="Ícone de quebra cabeça">
              <span class="d-block">Conexão</span>
            </div>
            <div class="mx-4">
              <img class="mb-3" height="50" src="assets/svg/honestidade.svg" alt="Ícone representando honestidade">
              <span class="d-block">Honestidade</span>
            </div>
            <div class="mx-4">
              <img class="mb-3" height="50" src="assets/svg/paixão.svg" alt="Ícone de coração">
              <span class="d-block">Paixão</span>
            </div>
            <div>
              <img class="mb-3" height="50" src="assets/svg/exelencia.svg" alt="Ícone medalha">
              <span class="d-block">Exelência</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section >
      <mat-stepper [linear]="true" #stepper class="container">
        <mat-step [stepControl]="contact" label="Empresa">
          <h1 class="text-muted">Dados de contato</h1>
          <form [formGroup]="contact">
            <div class="row p-2">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>CNPJ</mat-label>
                <input matInput placeholder="Digite o CNPJ da empresa" formControlName="cnpj"  mask="00.000.000/0000-00">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Telefone</mat-label>
                <input matInput placeholder="Digite seu telefone" formControlName="phone" mask="(00) 0 0000 0000">
              </mat-form-field>
            </div>
            <div class="row p-2">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Nome de Contato</mat-label>
                <input matInput placeholder="Digite seu nome" formControlName="fullName" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="Digite seu e-mail" formControlName="mail" required>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox formControlName="privacyPolicy" class="d-block">
                Ao preencher o formulário, concordo em receber comunicações de acordo com os meus interesses e aceito os termos da <a href="/politica-de-privacidade" target="_blank">Política de Privacidade</a>.
              </mat-checkbox>
              <mat-checkbox formControlName="bacenAccepted" class="d-block">
                Autorizo a consulta de informações junto a birôs de crédito e ao <a target="_blank" rel="noopener" href="https://s3-sa-east-1.amazonaws.com/antecipagov.public.docs/AUTORIZA%C3%87%C3%83O+PARA+CONSULTA+JUNTO+AO+BACEN.pdf">BACEN</a>.
              </mat-checkbox>
              <mat-checkbox formControlName="pepAccepted">Declaro que não sou uma Pessoa Politicamente Exposta (PEP).</mat-checkbox>
            </div>
            <div class="d-flex justify-content-end">
              <button class="bg-primary" mat-stroked-button matStepperNext (click)="onSaveInitial()">Próximo</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="partner" label="Sócio">
          <h1 class="text-muted">Dados sócio responsável</h1>
          <form [formGroup]="partner">
            <div class="row p-2">
              <mat-form-field appearance="outline">
                <mat-label>Nome completo</mat-label>
                <input matInput placeholder="Digite seu nome completo" formControlName="fullName" required>
              </mat-form-field>
            </div>
            <div class="row p-2">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>CPF</mat-label>
                <input matInput placeholder="Digite seu CPF" formControlName="cpf" mask="000.000.000-00">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="Digite seu e-mail" formControlName="mail" >
              </mat-form-field>
            </div>
            <div class="row p-2">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>WhatsApp</mat-label>
                <input matInput placeholder="Digite o número para WhatsApp" formControlName="whatsapp"  mask="(00) 0 0000 0000">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Telefone</mat-label>
                <input matInput placeholder="Digite o número de telefone" formControlName="phone" mask="(00) 0 0000 0000">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-checkbox formControlName="digitalCertificate" class="d-block">
                Possuem certificado digital no nome do sócio administrador
              </mat-checkbox>
              <mat-checkbox formControlName="confirmPartner" class="d-block">
                Confirmo que os dados preenchidos são do sócio responsável pela empresa
              </mat-checkbox>
            </div>
            <div class="d-flex justify-content-end">
              <button class="mx-2" mat-stroked-button matStepperPrevious>Voltar</button>
              <button mat-stroked-button class="bg-primary" matStepperNext (click)="onSavePartner()">Próximo</button>
            </div>
          </form>
        </mat-step>
       <mat-step label="Documentos">
        <b class="d-block mb-2 h5">Documentos</b>
        <app-upload-card
          *ngFor="let document of documentList; let i = index"
          [item]="document"
          [showError]="showDocErrors"
          (add)="addDocument($event, i)"
          (remove)="removeDocument(i, $event)"
        >
        </app-upload-card>
        <div class="d-flex justify-content-end">
          <button class="mx-2" mat-stroked-button matStepperPrevious>Voltar</button>
          <button mat-stroked-button class="bg-primary" (click)="onDone()">Finalizar</button>
        </div>
       </mat-step>
      </mat-stepper>
    </section>
  </main>
}

@if (finished) {
  <app-success-page></app-success-page>
}
