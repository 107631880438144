import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { NgOptimizedImage } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-receivables-section',
  imports: [AppMaterialModule, NgOptimizedImage],
  templateUrl: './receivables-section.component.html',
  styleUrl: './receivables-section.component.scss'
})
export class ReceivablesSectionComponent {
  private broadUrl = environment.broad_website


  onWebSiteBroad() {
    window.open(this.broadUrl, '_blank');
  }
}
