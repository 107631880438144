<section class="container-fluid d-flex flex-column justify-content-center align-items-center py-4">
  <h1><span class="primary-light-color">Nós somos a </span> <span class="second-color">Broadfactor</span></h1>

  <video
    preload="metadata" width="auto" height="auto"
    controls
    poster="./assets/svg/thumb.png"
    class="my-4"
  >
    <source
      src="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/videos/Video+Ricardo+-+Antecipacao+de+recebiveis.mp4"
      type="video/mp4"
      rel="preload"
    />
    Seu navegador não suporta a reprodução de vídeos.
  </video>

  <p class="fs-4 text-muted p-3">
    Quando você escolhe a Broadfactor, você está escolhendo o melhor para seu negócio!
  </p>
</section>

