
<section class="container-fluid bg-light-dark py-5">
  <h1 class="my-4 text-center text-muted">Ficou com alguma dúvida? </h1>
  <section class="my-4 d-flex justify-content-center">
    <app-asks-expansion-panel class="asks"></app-asks-expansion-panel>
  </section>

  <p class="fs-4 text-muted text-center">
    Você tem alguma dúvida que não foi respondida aqui?
    <b><a class="primary-color" href="/central-de-ajuda" target="_blank">Acesse nossa central de ajuda!</a></b>
  </p>

</section>
