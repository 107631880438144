<mat-drawer-container autosize class="h-100">
  <mat-drawer #drawer class="p-2" mode="over" position="end" class="w-50">
    <div class="text-end mb-2 d-flex justify-content-around align-items-center ">
      <img src="assets/svg/logo-broadfactor.svg" alt="Logo da Broadfactor" width="100" height="100" loading="lazy">
      <button mat-icon-button (click)="drawer.toggle()" class="text-right">
          <mat-icon class="mat-18">close</mat-icon>
      </button>
    </div>
    <ul class="d-flex flex-column justify-content-between align-items-center mb-0">
      <li (click)="redirectTo('home')">Início </li>
      <li>
        <a href="https://broadfactor.com/broadfactor/sobre" target="_blank">Sobre</a>
      </li>
      <li (click)="redirectTo('solucoes')">Soluções</li>
      <li class="d-none" (click)="redirectTo('login')">Login</li>
      <button mat-flat-button class="bg-broad" (click)="redirectTo('register')">Crie sua Conta</button>
    </ul>
  </mat-drawer>

  <div class="main-content">
    <app-header (menu)="drawer.toggle()" (register)="redirectTo('register')"></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</mat-drawer-container>
