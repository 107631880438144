import { Routes } from '@angular/router';

import { DefaultLayoutComponent } from './core/shell/default-layout/default-layout.component';
import { FrequentlyAskedQuestionsComponent } from './pages/faq/shell/faq.component';
import { HelpCenterComponent } from './pages/help-center/help-center.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RegisterFormsComponent } from './pages/register/shell/register-forms/register-forms.component';
import { SolutionsComponent } from './pages/solutions/shell/solutions/solutions.component';
import { LayoutComponent } from './pages/landing-page/shell/layout/layout.component';
import { WebsiteHomeComponent } from './pages/landing-page/shell/website-home/website-home.component';

export function routes(): Routes {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  switch(subdomain) {
    case 'b2g':
      return [
        {
          path: '',
          component: DefaultLayoutComponent,
          children: [
            {path: 'home', component: HomeComponent},
            {path: 'register', component: RegisterFormsComponent},
            {path: 'politica-de-privacidade', component: PrivacyPolicyComponent},

            {path: '', redirectTo: '/home', pathMatch: 'full' },
            {path: 'evento', redirectTo: '/register', pathMatch: 'full' },
            {path: '**', redirectTo: '/home' ,pathMatch: 'full'}
          ]
        },
      ];
    default:
      return [
        {
          path: 'home',
          component: LayoutComponent,
          children: [
            {path: '', component: WebsiteHomeComponent},
          ]
        },
        {
          path: 'register',
          component: LayoutComponent,
          children: [
            {path: '', component: RegisterFormsComponent},
          ]
        },
        {
          path: 'politica-de-privacidade',
          component: DefaultLayoutComponent,
          children: [
            { path: '', component: PrivacyPolicyComponent },
          ]
        },
        {
          path: 'central-de-ajuda',
          component: LayoutComponent,
          children: [
            { path: '', component: HelpCenterComponent },
          ]
        },
        {
          path: 'perguntas-frequentes',
          component: DefaultLayoutComponent,
          children: [
            { path: '', component: FrequentlyAskedQuestionsComponent },
          ]
        },
        {
          path: 'solucoes',
          component: LayoutComponent,
          children: [
            { path: '', component: SolutionsComponent },
          ]
        },

        {path: '', redirectTo: '/home', pathMatch: 'full' },
        {path: '**', redirectTo: '/home' ,pathMatch: 'full'}
      ];
  }
}
