<mat-card appearance="outlined">
  <mat-card-content class="d-flex align-items-center">
    <img
      class="me-4"
      [ngSrc]="'assets/svg/landing-page/solutions/' + data.icon"
      width="58"
      height="57"
      priority
    >
    <span>
      <b class="d-block">{{ data.title }}</b>
      <span class="d-block">{{ data.description }}</span>
    </span>
  </mat-card-content>
</mat-card>
