<div class="initial container-fluid row d-flex justify-content-center align-items-center">
  <div class="col-md-6 col-sm-6">
    <span class="h1">
      <span class="color-broad-pay d-block">Broadfactor Pay</span>
      <span class="d-block">A sua conta digital PJ</span>
    </span>
    <p class="w-75 my-4">Abra sua conta e facilite a sua obtenção de crédito através da antecipação de seus recebíveis</p>
    <button
      mat-flat-button
      class="bg-broad bg-white"
      (click)="redirectTo('register')"
    >
      Crie sua Conta
    </button>
  </div>
  <img class="col-md-4 col-sm-6 d-none d-md-block" src="assets/svg/landing-page/initial.svg" alt="">
</div>
