<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> O que é a Broadfactor? </mat-panel-title>
    </mat-expansion-panel-header>
    <span>
      A Broadfactor é uma fintech criada para facilitar e impulsionar os negócios dos empreendedores brasileiros. Nossas principais soluções incluem:
    </span>
    <ul>
      <li>Antecipação de recebíveis: uma plataforma para negociações diárias de crédito, rápida e segura.</li>
      <li>Broadfactor Pay: conta digital de serviços bancários para pessoas jurídicas que centraliza e otimiza suas operações financeiras e de crédito.</li>
      <li>AntecipaGov: solução especializada na antecipação de contratos de fornecimento para o governo federal.</li>
    </ul>
    <span>
      Estamos comprometidos em oferecer ferramentas inovadoras que simplifiquem a gestão financeira e apoiem o crescimento da sua empresa.
    </span>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Como posso abrir uma conta PJ? </mat-panel-title>
    </mat-expansion-panel-header>
    <p>Basta acessar o site <a href="https://broadfactorpay.com/register" target="_blank">https://broadfactorpay.com/register</a>, preencher os dados solicitados e enviar os documentos obrigatórios. Após isso, um consultor avaliará suas informações e entrará em contato para dar sequência ao processo.</p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Quais são os documentos obrigatórios básicos?</mat-panel-title>
    </mat-expansion-panel-header>
    <span> Para o cadastro, você precisará enviar os seguintes documentos:</span>
    <ul>
      <li>Contrato social da empresa consolidado.</li>
      <li>Documento de identificação válido dos sócios.</li>
      <li>Comprovante de residência dos sócios com menos de 90 dias.</li>
      <li>Comprovante de endereço da empresa com menos de 90 dias.</li>
    </ul>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Depois que abrir minha conta, como acesso?</mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li>
        Computador: clique na opção "Login", localizada no cabeçalho do site.
      </li>
      <li>
        Celular: baixe o aplicativo "Broadfactor Pay" na loja de aplicativos do seu smartphone.
      </li>
    </ul>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Sou Pessoa Física (PF), posso abrir uma conta? </mat-panel-title>
    </mat-expansion-panel-header>
    <p>Não. O Broadfactor Pay está disponível exclusivamente para Pessoas Jurídicas (PJ).</p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Sou Microempreendedor Individual (MEI), posso abrir uma conta?</mat-panel-title>
    </mat-expansion-panel-header>
    <p>Sim. Microempreendedores Individuais (MEIs) são elegíveis para abrir contas no Broadfactor Pay.</p>
  </mat-expansion-panel>
</mat-accordion>
