import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-main-section',
  imports: [AppMaterialModule, NgOptimizedImage, RouterLink],
  templateUrl: './main-section.component.html',
  styleUrl: './main-section.component.scss'
})
export class MainSectionComponent {

}
