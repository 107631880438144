<section class="container-fluid row d-flex justify-content-center h-50 p-5 bg-light-dark">
  <div class="col-lg-11">
    <h1 class="primary-light-color mb-5">Por que escolher a <span class="second-color">Broadfactor ?</span></h1>
    <div class="my-4 text-center">
      <swiper-container init="false" #swiper class="custom-swiper">
        <swiper-slide class="swiper-slide">
          <div class="card">
            <img
              src="assets/svg/landing-page/home/padlock.svg"
              alt="Ícone de cadiado"
              width="98"
              height="118"
              loading="lazy"
            >
            <span class="d-block describe text-center">Plataforma 100% <b>digital e segura</b></span>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="card">
            <img
              src="assets/svg/landing-page/home/computer.svg"
              alt="Ícone de computador com smartphone"
              width="98"
              height="118"
              loading="lazy"
            >
            <span class="d-block describe text-center">Acesso via <b>App e Internet Banking</b></span>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="card">
            <img
              src="assets/svg/landing-page/home/graph.svg"
              alt="Ícone de gráfico"
              width="98"
              height="118"
              loading="lazy"
            >
            <span class="d-block describe text-center">Serviços e <b>crédito inovadores</b></span>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="card">
            <img
              src="assets/svg/landing-page/home/bank.svg"
              alt="Ícone de banco"
              width="98"
              height="118"
              loading="lazy"
            >
            <span class="d-block describe text-center">Crédito fornecedores <b>(empresarial e governo)</b></span>
          </div>
        </swiper-slide>
      </swiper-container>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</section>
