import { Router, RouterLink } from '@angular/router';
import { AppMaterialModule } from './../../../../shared/app-material/app-material.module';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-header',
    imports: [AppMaterialModule, RouterLink],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Output() menu = new EventEmitter<void>();
  @Output() register = new EventEmitter<void>();

  constructor(private router: Router) { }

  onClick () {
    this.menu.emit();
  }

  goToSection(sectionID: string){
    document.getElementById(sectionID)?.scrollIntoView({behavior: 'smooth'});
  }

  onRegister(){
    this.register.emit();
  }

  redirectTo(route: string){
    this.router.navigate([route]);
  }
}
