import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../shared/app-material/app-material.module';

@Component({
    selector: 'app-footer',
    imports: [AppMaterialModule],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  
}
