<div class="container-fluid row d-flex justify-content-lg-end justify-content-md-center align-items-center py-5  bg-light-dark">
  <div class="col-lg-6 col-md-12 order-2 order-lg-1">
    <h1>
      <span class="primary-light-color">Antecipação de recebíveis das vendas</span> <span class="second-color"> para empresas ou governo</span>
    </h1>
    <p class=" my-4 fs-5">
      <b class="text-muted">Na Broadfactor temos o crédito ideal </b> para equilibrar
      seu fluxo de caixa, renovar os produtos e acelerar a
      evolução do seu negócio.
    </p>
    <div class="text-center">
      <button
        mat-stroked-button
        class="second-color p-4"
        (click)="onWebSiteBroad()"
      >
      <mat-icon class="mat-18">maps_home_work</mat-icon>
        <span class="fs-5">Conheça a Broadfactor</span>
      </button>
    </div>
  </div>
  <figure class="col-md-5 col-sm-6 order-1 order-lg-2">
    <img
      class="w-100"
      src="assets/svg/landing-page/home/receivables-section.svg"
      alt="Uma mulher segurando um celular exibindo o aplicativa da Broadfactor Pay"
      loading="lazy"
    >
  </figure>
</div>
