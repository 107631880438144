import { Component } from '@angular/core';
import { AppMaterialModule } from '../../../shared/app-material/app-material.module';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-default-layout',
    imports: [AppMaterialModule, HeaderComponent, FooterComponent, RouterOutlet],
    templateUrl: './default-layout.component.html',
    styleUrl: './default-layout.component.scss'
})
export class DefaultLayoutComponent {

}
