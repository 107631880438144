import { Component, OnInit } from '@angular/core';
import { AppMaterialModule } from '../../../../shared/app-material/app-material.module';

@Component({
  selector: 'app-asks-expansion-panel',
  templateUrl: './asks-expansion-panel.component.html',
  styleUrls: ['./asks-expansion-panel.component.scss'],
  standalone: true,
  imports: [AppMaterialModule]
})
export class AsksExpansionPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
