<mat-toolbar class="d-flex justify-content-between align-items-center px-5">
  <img class="w-logo" src="assets/svg/logo-broadfactor-colorido.svg" alt="Logo da Broadfactor">
  <ul class="d-none d-md-flex justify-content-between align-items-center mb-0">
    <li (click)="redirectTo('home')">Início </li>
    <li>
      <a href="https://broadfactor.com/broadfactor/sobre" target="_blank">Sobre</a>
    </li>
    <li (click)="redirectTo('solucoes')">Soluções</li>
    <li class="d-none" (click)="redirectTo('login')">Login</li>
    <button mat-flat-button class="bg-broad" (click)="onRegister()">Crie sua conta</button>
  </ul>
  <button mat-icon-button aria-label="Botão de Menu" (click)="onClick()" class="d-block d-md-none">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
