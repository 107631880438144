import { AppMaterialModule } from '../../../shared/app-material/app-material.module';
import { Component, Input, signal } from '@angular/core';
import { AsksExpansionPanelComponent } from '../components/asks-expansion-panel/asks-expansion-panel.component';

@Component({
  selector: 'app-frequently-asked-questions',
  imports: [AppMaterialModule, AsksExpansionPanelComponent],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FrequentlyAskedQuestionsComponent {
  readonly panelOpenState = signal(false);
  @Input() isComponent = false;
}
