<main class="min-vh-100">
  <section class="d-flex flex-column align-items-center border bg-light-dark py-4">
    <h1 class="text-center">Conta Digital: Soluções completas para sua gestão financeira</h1>
    <p>
      Descubra como nossa conta digital pode facilitar seu dia a dia e impulsionar seus negócios.
    </p>
  </section>

  <div class="px-4">
    <section class="row m-0">
      <div class="col-md-6">
          @for (item of solutionList; track $index) {
            @if ($index % 2 == 0) {
              <app-simple-card [data]="item"></app-simple-card>
            }
          }
      </div>
      <div class="col-md-6">
        @for (item of solutionList; track $index) {
          @if ($index % 2 != 0) {
            <app-simple-card [data]="item"></app-simple-card>
          }
        }
      </div>
    </section>
  </div>

  <section class="w-100 d-flex justify-content-center pb-5">
    <a href="https://www.instagram.com/broadfactor" target="_blank">
      <img src="assets/svg/instagram-icon-colorido.svg" alt="Ícone que redireciona para o instagram da Broadfactor" class="w-20">
    </a>
    <a  class="mx-4" href="https://www.linkedin.com/company/broadfactor" target="_blank">
      <img src="assets/svg/linkedin-icon-colorido.svg" alt="Ícone que redireciona para o linkedin da Broadfactor" class="w-20">
    </a>
    <a href="https://www.youtube.com/channel/UChxZcuH2MjPLA7ebO4VKqUA" target="_blank">
      <img src="assets/svg/youtube-icon-colorido.svg" alt="Ícone que redireciona para o youtube da Broadfactor" class="w-20">
    </a>
  </section>

</main>
