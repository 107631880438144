import { IDocumentList } from "../interfaces/IDocumentList";

export class RegisterDocuments {
  static list(): IDocumentList[] {
    return [
      {
        description: 'Última alteração do contrato social',
        type: 'CONTRATO_SOCIAL',
        required: true,
        multiple: false,
        files: [],
        canDelete: false,
        isLoading: false,
        isCompanyDocument: true,
      },
      {
        description: 'Documento de identificação do sócio (CNH ou Identidade)',
        type: 'IDENTIFICACAO',
        required: true,
        multiple: false,
        files: [],
        canDelete: false,
        isLoading: false,
        tooltip: 'Inclua o documento de identificação do sócio responsável (ou representantes legal responsável) da empresa.',
        isCompanyDocument: false
      },
      {
        description: 'Comprovante de residência atualizado dos sócio responsável',
        type: 'ENDERECO',
        required: true,
        multiple: false,
        files: [],
        canDelete: false,
        isLoading: false,
        tooltip: 'Inclua o comprovante de residência atualizado do sócio responsável (ou representante legal responsável) da empresa.',
        isCompanyDocument: false
      },
    ];

  }
}
