<app-main-section></app-main-section>

<app-complete-solution-section></app-complete-solution-section>

<app-why-us></app-why-us>

<app-step-by-step-section></app-step-by-step-section>

<app-receivables-section></app-receivables-section>

<app-welcome-section></app-welcome-section>

<app-faq-section></app-faq-section>
