<div class="container-fluid row d-flex justify-content-center  justify-content-lg-start align-items-center">
  <img
    class="col-md-4 col-sm-6 my-4"
    width="388"
    height="380"
    ngSrc="assets/svg/landing-page/home/complete-solution-section.svg"
    alt="Uma mulher segurando um celular exibindo o aplicativa da Broadfactor Pay"
    loading="lazy"
  >
  <div class="col-lg-7 col-md-12 col-sm-12">
    <h1 class="primary-light-color">
      Uma <span class="second-color">solução financeira </span> completa com <span class="second-color"> crédito disponível </span> para seu negócio
    </h1>
    <p class=" my-4 fs-5">
      <b class="text-muted">O crédito que você precisa</b>, integrado a uma solução financeira que economiza tempo e fortalece o crescimento do seu negócio.
    </p>
  </div>
</div>
