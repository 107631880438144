import { isPlatformBrowser } from '@angular/common';
import { afterNextRender, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-why-us',
  imports: [],
  templateUrl: './why-us.component.html',
  styleUrl: './why-us.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WhyUsComponent {
  @ViewChild('swiper') swiper!: ElementRef<any>;

	swiperConfig: SwiperOptions = {
		spaceBetween: 30,
		slidesPerView: 4,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		direction: 'horizontal',
		breakpoints: {
			'100': {
				slidesPerView: 1,
			},
			'680': {
				slidesPerView: 2,
			},
			'1200': {
				slidesPerView: 3
			}
		},
		autoplay: {
			delay: 5000,
      disableOnInteraction: false
		},
		updateOnWindowResize: true,
    loop: true,
    speed: 500,
	}

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		afterNextRender(() => {
			this.handleUpdateSwiperConfig();
		})
	}

	handleUpdateSwiperConfig(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.swiperConfig = {
        ...this.swiperConfig,
        direction: 'horizontal',
      };
    };
    Object.assign(this.swiper.nativeElement, this.swiperConfig);
    this.swiper.nativeElement.initialize();
	}
}


